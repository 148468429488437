.form-row.row-tags {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.form-row.row-tags .input-group {
    flex: 1 1 auto;
}
.item-filters .dropdown-item {
    display: flex;
    justify-content: space-between;
}
.tab-content {
    margin-top: 18px;
}
