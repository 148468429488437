#changeset {
    position: fixed;
    z-index: 10000;
    top: 68px;
    right: 10px;
}

#changeset-box {
    position: absolute;
    top: 50px;
    right: 0;
    width: 300px;
}