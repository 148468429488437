
th {
    position: sticky;
    top: 0px;
    background: white;
    border-top: none!important;
    border-bottom: none!important;
    box-shadow: 0 1px 0 #dee2e6;
    z-index: 2;
}
table {
    border-spacing: 0px;
}

td input {
    max-width: 150px;
}

td.category {
    white-space: nowrap;
    vertical-align: middle!important;
}


.icon-btn {
    cursor: pointer;
    opacity: .7;
}
.icon-btn:hover { opacity: 1 }
