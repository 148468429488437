.navbar {
    z-index: 1000;
    max-height: 60px;
}

.avatar {
    width: 50px;
    height: 50px;
}

span.version {
    font-size: 0.6em;
    color: #777;
}
