.container-fluid.App {
    text-align: center;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

.card.card-items {
    position: absolute;
    left: 0px;
    top: 60px;
    max-height: calc(100% - 60px);
    z-index: 10000;
    width: 60%;
}

.card.card-items .card-header {
    background: none;
}

.card.card-items  .card-body {
    position: relative;
    overflow-y: auto;
    min-height: 100px;
    padding-top: 0;
}

.card.card-items  .card-body.items-loading {
    overflow-y: hidden;
}

.items-loading::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,.5);
    z-index: 2;
}

.leaflet-container {
    width: 40%;
    right: -60%;
}
